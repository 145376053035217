import "./Hangaround.css";
import { Grid, Container } from "@mui/material";
import Header from "../Includes/Header";
import React, { useEffect, useState } from "react";
import axios from "axios";
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { _BASE_URL } from "../../Config/Constants";

const Hangaround = () => {
    const [resumes,setResumes] = useState([])
    const [hangList,setHangList] = useState([]);
    useEffect(()=>{
        
        axios.post(_BASE_URL + "/filter-resume", {searchInDoc:false,page:0,rowsPerPage:50000,isHangaround:'Yes'})
        .then((result) => {
          if (result.status == 200) {          
            setResumes(result.data.empList);
            let hList=[];
            result.data.empList.map((emp)=>{
              const hItem=hList.find(h=>h.toLowerCase()===emp.occupation.toLowerCase())
                if(!hItem && hList.indexOf(emp.occupation) ===-1){
                  hList.push(emp.occupation);
                }
            });
            hList.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
            setHangList(hList);
            console.log("hListhListhList",hList)
          }
        }).catch(e=>{
            console.log(e)
        })
    },[])
  const getEmpByOccupation=(occ)=>{
    let empList=resumes.filter((emp)=>emp.occupation.toLowerCase() ===occ.toLowerCase())
    return <Grid container gap={5} sx={{ justifyContent: 'center' }}>
    {empList.map(emp=>(
    // <Grid key={emp.id} item sm={2} lg={1} className={"prof-tile"} style={{textAlign:"center"}} >
    //     <div style={{minHeight:"140px",border:"1px solid #ccc"}}> 
    //     <img src={_BASE_URL+'/'+ (emp.photo?emp.photo:"no-pic.png")} alt={"Photo"}  width={"100%"} />
    //     </div>
    //     <h4 style={{marginBottom:"5px",marginTop:"3px"}}>{emp.fullName}</h4>
    //     <span >{emp.note}</span>
    // </Grid>
    <Grid key={emp.id} item sm={2} lg={1} className={"prof-tile"} style={{ textAlign: "center" }}>
    <div style={{ minHeight: "175px", border: "1px solid #ccc", display: "flex", justifyContent: "center", alignItems: "center", overflow: "hidden" }}> 
        <img 
            src={_BASE_URL + '/' + (emp.photo ? emp.photo : "no-pic.png")} 
            alt="Photo" 
            style={{ height: "175px", width: "auto", objectFit: "cover" }}
        />
    </div>
    <h4 style={{ marginBottom: "5px", marginTop: "3px" }}>{emp.fullName}</h4>
    <span>{emp.note}</span>
</Grid>

    ))}
</Grid>
  }
  return (
    <>
      <React.Fragment>
        <Header />
        <Container
          maxWidth="100%"
          className="settings-container"
          style={{ marginTop: "70px",background:"#eee",height:"100vh" }}
        >
          {/* <Grid container className={"page-head-bar"}>
            <Grid item sm={6}>
              <h3 className={"pagehead"}>Hangaround</h3>
            </Grid>
          </Grid>
            <hr/> */}
            
            {hangList.map((list,index)=>(
                  <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography component="h2" style={{fontWeight:"bold"}}>{list}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {getEmpByOccupation(list)}
        </AccordionDetails>
        </Accordion>
            ))}
            
        </Container>
      </React.Fragment>
    </>
  );
};

export default Hangaround;
