import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { Login } from './Pages/Login';
import { Dashboard } from './Pages/Dashboard';
import Education from "./Components/Settings/Education";
import Profession from "./Components/Settings/Profession";
import Skill from "./Components/Settings/Skill";
import Work from "./Components/Settings/Work";
import Branch from "./Components/Settings/Branch";
import Residence from "./Components/Settings/Residence";
import Knownby from "./Components/Settings/Knownby";
import User from "./Components/User/User";
import PasswordReset from "./Components/User/PasswordReset";
import React from 'react';
import useToken from './useToken';
import Nationality from './Components/Settings/Nationality';
import Language from './Components/Settings/Language';
import Triptype from "./Components/Trip/TripType";
import Client from "./Components/Client/Client";
import Project from "./Components/Project/Project";
import Trip from "./Components/Trip/Trip";
import TripInactive from "./Components/TripInactive/TripInactive"
import NotFound from "./Components/NotFound";
import Hangaround from './Components/Hangaround/Hangaround';
function App() {
  const { token, setToken } = useToken();
  const [userRole, setUserRole] = React.useState("");
  const [permissions,setPermissions]=React.useState([]);

  React.useEffect(() => {
    const _token = localStorage.getItem("token");
    if (_token) {
        const tokenArr = JSON.parse(_token);
        
        if (tokenArr && tokenArr.role)
            setUserRole(tokenArr.role);
        if(tokenArr && tokenArr.permissions){
          setPermissions(tokenArr.permissions)
        }
    }
}, []);
  if(!token) {
    return <Login setToken={setToken} loginProp={"test"} />
  }
  return (
    <Router>
      <div className="Apps">
        <Routes>
        {permissions.includes('trip')?<Route path={"/"} element={<TripInactive />} />:
         <Route path={"/"} element={<PasswordReset />} />
        }
        {permissions.includes('hr')? <Route path={"/hr"} element={<Dashboard userRole={userRole} />} />  :""}        
          <Route path={"/branch"} element={<Branch/>} />
          <Route path={"/hangaround"} element={<Hangaround/>} />
          <Route path={"/nationality"} element={<Nationality/>} />
          <Route path={"/language"} element={<Language/>} />
          <Route path={"/education"} element={<Education/>} />
          <Route path={"/proffesion"} element={<Profession/>} />
          <Route path={"/skills"} element={<Skill/>} />
          <Route path={"/residence"} element={<Residence/>} />
          <Route path={"/work"} element={<Work/>} />
          <Route path={"/knownby"} element={<Knownby/>} />
          <Route path={"/users"} element={<User/>} />
          <Route path={"/password-reset"} element={<PasswordReset />} />
          <Route path={"/triptype"} element={<Triptype />} />
          {permissions.includes('client')?<Route path={"/client"} element={<Client />} />:""}     
          {permissions.includes('project')?<Route path={"/project"} element={<Project />} />:""}
          <Route path={"/login"} element={<Login />} />
          {permissions.includes('trip')? <Route path={"/trip"} element={<TripInactive />} />:""}
          <Route path={"/trip-inactive"} element={<Trip />} />
          <Route path="*" element={<NotFound/>}/>
        </Routes>
      </div>
    </Router>
  );
}

export default App;