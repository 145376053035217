import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { _EMPDATA } from "../../Config/sample";
import GetAppIcon from "@mui/icons-material/GetApp";
import CommentIcon from "@mui/icons-material/Comment";
import StarIcon from "@mui/icons-material/Star";
import Highlighter from "react-highlight-words";
import PlagiarismIcon from "@mui/icons-material/Plagiarism";
import { _BASE_URL } from "../../Config/Constants";
function createData(name, calories, fat, carbs, protein) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
  };
}

const rows = _EMPDATA;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]); 
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "age",
    numeric: true,
    disablePadding: false,
    label: "Age",
  },
  {
    id: "Nationality",
    numeric: true,
    disablePadding: false,
    label: "Nationality",
  },
  {
    id: "Place of residence",
    numeric: true,
    disablePadding: false,
    label: "Place of residence",
  },
  {
    id: "Education",
    numeric: true,
    disablePadding: false,
    label: "Education",
  },
  {
    id: "Profession",
    numeric: true,
    disablePadding: false,
    label: "Profession",
  },
  {
    id: "Field of work",
    numeric: true,
    disablePadding: false,
    label: "Field of work",
  },
  {
    id: "View Details",
    numeric: true,
    disablePadding: false,
    label: "",
  },
  {
    id: "Comments",
    numeric: true,
    disablePadding: false,
    label: "",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    fetchData
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : null}

      {/* {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (null
      )} */}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EmpGrid({
  resumes,
  handleViewResume,
  handleViewComment,
  setGroupMailMembers,
  searchTerm,
  handleReloadData,
  searchArray,
  totalRows,
  page,
  rowsPerPage
}) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [pagezz, setPage] = React.useState(0);
  const [rowsPerPagezzz, setRowsPerPage] = React.useState(10);
  const rows = resumes;
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  React.useEffect(() => {
    console.log("resumesresumesresumesresumes",resumes)
  }, [resumes]);
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      setGroupMailMembers(newSelecteds);
      return;
    }
    setGroupMailMembers([]);
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setGroupMailMembers(newSelected);
    setSelected(newSelected);
  };

  // React.useEffect(() => {
  //   handleReloadData(page+1,rowsPerPage);
  // }, [page, rowsPerPage]);

  // Handle page change
  const handlePageChange = (event, newPage) => {
    //setPage(newPage);
    handleReloadData(newPage,rowsPerPage);
  };

  // Handle rows per page change
  const handleRowsPerPageChange = (event) => {
    // setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0); // Reset to the first page
    handleReloadData(0,parseInt(event.target.value, 10));
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const viewResume = (id) => {
    handleViewResume(id);
  };
  const viewComments = (id) => {
    handleViewComment(id);
  };
  const _calculateAge = (dateString) => {
    // birthday is a date
    let today = new Date();
    let birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age < 10 || age > 110 ? "Unknown" : age;
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const getProfCombained = (proffesion) => {
    const arr = proffesion.map((item) => item.proffesion);
    return arr.join(", ");
  };
  const deleteResume = (id) => {
    if (
      window.confirm("Are you sure you want to delete this Employee Details?")
    ) {
      const param = { id: id };
      axios
        .post(_BASE_URL + "/deleteResume/" + id)
        .then((response) => {
          handleReloadData();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getEduCombained = (edu) => {
    const arr = edu.map((item) => item.education);
    return arr.join(", ");
  };
  const getWorkCombained = (workFields) => {
    const arr = workFields.map((item) => item.work);
    return arr.join(", ");
  };
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) *  rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }} className={"loadTool"}>
        <EnhancedTableToolbar
          className={"loadTool"}
          numSelected={selected.length}
        />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"small"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={rows.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(rows, getComparator(order, orderBy))
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          className={"vpuplecor"}
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                          onClick={(event) => handleClick(event, row.id)}
                        />
                      </TableCell>
                      <TableCell
                        className={"vpuplecor"}
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        style={{ position: "relative" }}
                      >
                        <Highlighter
                          highlightClassName="YourHighlightClass"
                          searchWords={searchArray}
                          autoEscape={true}
                          textToHighlight={`${row.name} ${row.surName}`}
                        />
                        <sub className="docFound">
                          {" "}
                          {typeof row.foundDoc != "undefined" &&
                          row.foundDoc == true ? (
                            <Tooltip title="Found in document">
                              <PlagiarismIcon />
                            </Tooltip>
                          ) : null}
                        </sub>
                      </TableCell>
                      <TableCell align="left" className={"vpuplecor"}>
                        {_calculateAge(row.dob)}
                      </TableCell>
                      <TableCell align="left" className={"vpuplecor"}>
                      <Highlighter
                          highlightClassName="YourHighlightClass"
                          searchWords={searchArray}
                          autoEscape={true}
                          textToHighlight={row.nation ? row.nation : ""}
                        />
                        {/* {row.nation} */}
                      </TableCell>
                      <TableCell align="left" className={"vpuplecor"}>
                        <Highlighter
                          highlightClassName="YourHighlightClass"
                          searchWords={searchArray}
                          autoEscape={true}
                          textToHighlight={row.residence ? row.residence : ""}
                        />
                      </TableCell>
                      <TableCell align="left" className={"vpuplecor"}>
                      <Highlighter
                          highlightClassName="YourHighlightClass"
                          searchWords={searchArray}
                          autoEscape={true}
                          textToHighlight={row.educations ? getEduCombained(row.educations) : ""}
                        />
                        {/* {getEduCombained(row.educations)} */}
                      </TableCell>
                      <TableCell align="left" className={"vpuplecor"}>
                      <Highlighter
                          highlightClassName="YourHighlightClass"
                          searchWords={searchArray}
                          autoEscape={true}
                          textToHighlight={row.proffesions ? getProfCombained(row.proffesions) : ""}
                        />
                        {/* {getProfCombained(row.proffesions)} */}
                      </TableCell>
                      <TableCell align="left" className={"vpuplecor"}>
                      <Highlighter
                          highlightClassName="YourHighlightClass"
                          searchWords={searchArray}
                          autoEscape={true}
                          textToHighlight={row.workFields ? getWorkCombained(row.workFields) : ""}
                        />
                        {/* {getWorkCombained(row.workFields)} */}
                      </TableCell>
                      <TableCell align="left">
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            viewResume(row.id);
                          }}
                          className={"viewdet"}
                        >
                          VIEW DETAILS
                        </span>
                      </TableCell>
                      <TableCell align="right" style={{ width: "120px" }}>
                        <IconButton
                          onClick={(e) => {
                            viewComments(row.id);
                          }}
                          aria-label="comment"
                        >
                          <CommentIcon className={"viecomment"} />
                        </IconButton>
                        <IconButton
                          onClick={(e) => {
                            deleteResume(row.id);
                          }}
                          aria-label="delete"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {/* {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 33 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )} */}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
        <TablePagination
        rowsPerPageOptions={[10,20,50]}
        component="div"
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
      </Paper>
    </Box>
  );
}
