import * as React from "react";
//import logo from '.././imgs/logo.png';

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import axios from "axios";
import SearchIcon from "@mui/icons-material/Search";
import "./index.css";
import LeftBar from "./LeftBar";
import AddResume from "./Dialogs/AddResume";
import ViewResume from "./Dialogs/ViewResume";
import { Link } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
// import CircularProgress from '@mui/material/CircularProgress';
import Stack from "@mui/material/Stack";
import MenuIcon from "@mui/icons-material/Menu";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import EmpGrid from "./EmpGrid";
import { _EMPDATA, _FITER_DATA } from "../../Config/sample";
import Header from "../Includes/Header";
import CancelIcon from "@mui/icons-material/Cancel";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { _BASE_URL } from "../../Config/Constants";
import GroupEmail from "./Dialogs/GroupEmail";
import Commentdialog from "./Dialogs/Commentdialog";
import SaveSearch from "./Dialogs/SaveSearch";
import ErrorBoundary from "../ErrorBoundary";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import AddBoxRoundedIcon from "@mui/icons-material/AddBoxRounded";
import SettingsIcon from "@mui/icons-material/Settings";
import { sortBy } from "lodash";
const drawerWidth = 240;

const darkTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#ffffff",
    },
  },
});
const menuItemsSettings = [
  { link: "/", label: "Resume List" },
  { link: "/branch", label: "Branch" },
  { link: "/proffesion", label: "Profession" },
  { link: "/work", label: "Field of Work" },
  { link: "/education", label: "Education" },
  { link: "/skills", label: "Skill" },
  { link: "/residence", label: "Place of Residence" },
  { link: "/knownby", label: "Known By" },
  { link: "/users", label: "Users" },
  { link: "/nationality", label: "Nationality" },
  { link: "/language", label: "Language" },
  { link: "/triptype", label: "Trip Type" },
  { link: "/password-reset", label: "Password Reset" },
];
export class DashboardComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterConfig: [],
      filterLoading: true,
      searchTerm: "",
      fixSearchTerm: "",
      filterOptions: {},
      openAddResume: false,
      resumes: [],
      totalRows: 0,
      resumeLoading: false,
      openViewResume: false,
      viewResumeLoading: false,
      resumeData: {},
      openGroupEmail: false,
      editId: 0,
      openCommentDialog: false,
      openSaveSearch: false,
      appliedSearch: {},
      groupMailMembers: [],
      searchInDoc: false,
      openSettings: false,
      page: 0,
      sortBy:"name",
      sortDir:"asc",
      rowsPerPage: 10,
    };
    this.updateFilter = this.updateFilter.bind(this);
    this.handleSearchTerm = this.handleSearchTerm.bind(this);
    this.handleSearchTermKey = this.handleSearchTermKey.bind(this);
    this.handleSearchTermSubmit = this.handleSearchTermSubmit.bind(this);
    this.toggleAddResume = this.toggleAddResume.bind(this);
    this.handleViewResume = this.handleViewResume.bind(this);
    this.closeViewResume = this.closeViewResume.bind(this);
    this.getResumes = this.getResumes.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleGroupMail = this.handleGroupMail.bind(this);
    this.editResume = this.editResume.bind(this);
    this.handleToggleComment = this.handleToggleComment.bind(this);
    this.toggleSaveSearch = this.toggleSaveSearch.bind(this);
    this.applyFilter = this.applyFilter.bind(this);
    this.clearAppliedFilter = this.clearAppliedFilter.bind(this);
    this.resetSearchTerm = this.resetSearchTerm.bind(this);
    this.toggleSettings = this.toggleSettings.bind(this);
  }

  componentDidMount() {
    console.log("userRole", this.props);
    axios.get(_BASE_URL + "/get-filter-config").then((result) => {
      if (result.status == 200) {
        this.setState({ filterConfig: result.data, filterLoading: false });
      }
    });
    //this.setState({ filterConfig: _FITER_DATA, filterLoading: false });

    this.getResumes({});
  }
  handleSort(e) {
    const sortOption = e;
    let filterOptions = { ...this.state.filterOptions };
   
    // updateFilter(filterOptions)
    if (sortOption == "Recently") {
      filterOptions["sortBy"] = "id";
    }
    if (sortOption == "Name") {
      filterOptions["sortBy"] = "name";
    }
    if (sortOption == "Residence") {
      filterOptions["sortBy"] = "residence";
    }
    if (sortOption == "Age") {
      filterOptions["sortBy"] = "dob";
    }
    this.setState({ sortParam: sortOption,sortBy:filterOptions["sortBy"] });
   this.updateFilter(filterOptions);
  }
  // handlePagiNation(cpage=0,rowNo=10){
  //   this.setState({page:cpage,rowsPerPage:rowNo })
  // }

  getFilterResult(filterOptions, searchTerm, page = 0, limit = 10) {
    this.setState({ page: page, rowsPerPage: limit });
    let filterChars = { ...filterOptions };
    filterChars["page"] = page;
    filterChars["rowsPerPage"] = limit;
    filterChars["searchTerm"] = searchTerm;
    filterChars["searchIndoc"] = this.state.searchIndoc;
    console.log(filterChars);
    this.getResumes(filterChars);
  }
  updateFilter(filterOptions) {
    console.trace("Update filter Index", filterOptions);
    this.setState({ filterOptions: filterOptions });
    this.getFilterResult(filterOptions, this.state.searchTerm);
  }
  handleSearchTerm(e) {
    this.setState({ searchTerm: e.target.value ? e.target.value : "" });
  }
  handleSearchTermKey(e) {
    if (e.keyCode === 13) {
      this.handleSearchTermSubmit(0, 10);
      return;
    }
  }

  handleSearchTermSubmit(page = 0, limit = 10) {
    if (typeof page == "object") page = 0;
    this.getFilterResult(
      this.state.filterOptions,
      this.state.searchTerm,
      page,
      limit
    );
  }
  handleDocSearch = (e) => {
    this.setState({ searchInDoc: e.target.checked });
    console.log(e.target.checked);
    // this.handleSearchTermSubmit();
  };
  resetSearchTerm() {
    this.setState({ searchTerm: "", fixSearchTerm: "", searchInDoc: false });
    this.getFilterResult(this.state.filterOptions, "");
  }
  toggleAddResume() {
    this.setState({ openAddResume: !this.state.openAddResume, editId: 0 });
  }
  getResumes(filterChars) {
    let params = filterChars ? filterChars : null;
    params["searchInDoc"] = this.state.searchInDoc;
    params[sortBy]=this.state.sortBy;
    this.setState({ resumeLoading: true, openAddResume: false, resumes: [] });
    axios.post(_BASE_URL + "/filter-resume", params).then((result) => {
      if (result.status == 200) {
        this.setState({
          resumes: result.data.empList,
          totalRows: result.data?.totalCount,
          resumeLoading: false,
          fixSearchTerm: this.state.searchTerm,
        });
      //  this.handleSort(this.state.sortParam);
      }
    });
    //this.setState({ resumes: _EMPDATA, resumeLoading: false });
  }
  handleViewResume(id) {
    this.setState({
      openViewResume: true,
      viewResumeLoading: true,
      resumeData: {},
    });
    this.getSingleResume(id);
  }
  getSingleResume = (id) => {
    axios.get(_BASE_URL + "/get-resume/" + id).then((result) => {
      if (result.status == 200) {
        this.setState({ resumeData: result.data, viewResumeLoading: false });
      }
    });
  };
  handleGroupMail() {
    this.setState({ openGroupEmail: !this.state.openGroupEmail });
  }
  closeViewResume() {
    this.setState({ openViewResume: false, resumeData: {} });
  }
  editResume(id) {
    this.setState({ openViewResume: false, editId: id, openAddResume: true });
  }
  handleToggleComment(id) {
    if (id) this.getSingleResume(id);
    this.setState({ openCommentDialog: id });
  }
  toggleSaveSearch() {
    const { openSaveSearch } = { ...this.state };
    this.setState({ openSaveSearch: !openSaveSearch });
  }
  applyFilter(ser) {
    const params = JSON.parse(ser.search);
    this.setState({
      openSaveSearch: false,
      appliedSearch: ser,
      searchTerm: params.searchTerm,
      filterOptions: params.filterOptions,
    });
    this.getFilterResult(params.filterOptions, params.searchTerm);
  }
  clearAppliedFilter() {
    this.setState({ appliedSearch: {}, searchTerm: "", filterOptions: {} });
    this.getFilterResult({}, "");
  }
  setGroupMailMembers = (mem) => {
    this.setState({ groupMailMembers: mem });
  };
  searchArrayWord = () => {
    let temp = this.state.searchTerm.split(" ");
    if (temp.length == 1) temp.push(this.state.searchTerm);
    console.log("temptemp", temp);
    return temp;
  };
  toggleSettings() {
    this.setState({ openSettings: !this.state.openSettings });
  }
  render() {
    const searchArray = this.searchArrayWord();

    return (
      <ErrorBoundary>
        <Box sx={{ display: "flex" }} className={"bodyf"}>
          <CssBaseline />
          <Header toggleAddResume={this.toggleAddResume} />
          {this.state.openSettings && (
            <Drawer
              anchor={"right"}
              open={this.state.openSettings}
              onClose={this.toggleSettings}
            >
              <Box sx={{ width: 250 }}>
                <List>
                  {menuItemsSettings.map((item, index) => (
                    <ListItem
                      button
                      key={index}
                      component={Link}
                      to={item.link}
                      className="menuItems"
                    >
                      <ListItemText
                        primary={item.label}
                        className="menuItems"
                      />
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Drawer>
          )}

          <Box className="main" sx={{ flexGrow: 1 }}>
            <Grid container direction="row" style={{ width: "100%" }}>
              <Grid
                item
                lg={3}
                style={{
                  width: "250px",
                  maxWidth: "250px",
                  borderRight: "1px solid #ddd",
                }}
              >
                <LeftBar
                  resumeLoading={this.state.resumeLoading}
                  filterConfig={this.state.filterConfig}
                  appliedFilter={this.state.appliedSearch}
                  filterLoading={this.state.filterLoading}
                  updateFilter={this.updateFilter}
                />
              </Grid>
              <Grid item lg={true}>
                <Container>
                  <Grid container>
                    <Grid item sm={9}>
                      <Typography
                        className={"filterhead reshead"}
                        variant="h6"
                        noWrap
                        component="div"
                      >
                        Resume List
                      </Typography>
                    </Grid>
                    <Grid item sm={3} style={{ textAlign: "end" }}>
                      <IconButton
                        onClick={this.toggleAddResume}
                        style={{ color: "#1a588c" }}
                        fontSize={"large"}
                      >
                        <AddBoxRoundedIcon />
                      </IconButton>
                      <IconButton
                        onClick={this.toggleSettings}
                        style={{ color: "#1a588c" }}
                        fontSize={"large"}
                      >
                        <SettingsIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item md={6}>
                      <Box>
                        <Paper
                          component="div"
                          className={"search-box newbox"}
                          sx={{
                            p: "2px 2px",
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Search .."
                            inputProps={{ "aria-label": "search google maps" }}
                            onKeyUp={this.handleSearchTermKey}
                            onChange={this.handleSearchTerm}
                            value={this.state.searchTerm}
                            size="small"
                            disabled={this.state.resumeLoading}
                          />
                          <IconButton
                            type="button"
                            sx={{ p: "10px" }}
                            onClick={this.handleSearchTermSubmit}
                            aria-label="search"
                          >
                            <SearchIcon />
                          </IconButton>
                        </Paper>
                      </Box>
                    </Grid>
                    <Grid item md={1}></Grid>
                    <Grid item md={2}>
                      {this.state.searchTerm.length > 0 ? (
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={this.state.searchInDoc}
                                onChange={this.handleDocSearch}
                              />
                            }
                            label="Search in doc"
                          />
                        </FormGroup>
                      ) : null}
                    </Grid>
                    <Grid item md={1}></Grid>
                    <Grid item md={2}>
                      <FormControl fullWidth size="small">
                        <InputLabel id="demo-simple-select-label">
                          Sort by
                        </InputLabel>
                        <Select
                          className={"sortbox"}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={this.state.sortParam}
                          label="Sort by"
                          onChange={(e) => this.handleSort(e.target.value)}
                        >
                          <MenuItem value={"Recently"}>Recently added</MenuItem>
                          <MenuItem value={"Age"}>Age</MenuItem>
                          <MenuItem value={"Name"}>Name</MenuItem>
                          <MenuItem value={"Residence"}>Residence</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <hr />
                    <Grid item md={12}>
                      {typeof this.state.appliedSearch.id != "undefined" ? (
                        <span>
                          <strong>FIlter applied::</strong>{" "}
                          {this.state.appliedSearch.title}{" "}
                          <IconButton onClick={this.clearAppliedFilter}>
                            <CancelIcon />
                          </IconButton>
                        </span>
                      ) : null}
                      {this.state.fixSearchTerm != "" ? (
                        <p>
                          <i>Search for</i>:{" "}
                          <strong>{this.state.fixSearchTerm}</strong>{" "}
                          <span
                            onClick={this.resetSearchTerm}
                            className="clearSearch"
                          >
                            Clear
                          </span>{" "}
                        </p>
                      ) : null}
                    </Grid>
                    <Grid item md={12}>
                      {this.state.resumeLoading ? (
                        <Box sx={{ width: "100%" }}>
                          <LinearProgress />
                        </Box>
                      ) : null}
                      <EmpGrid
                        searchTerm={this.state.searchTerm}
                        searchArray={searchArray}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        setGroupMailMembers={this.setGroupMailMembers}
                        resumes={this.state.resumes}
                        totalRows={this.state.totalRows}
                        handleViewComment={this.handleToggleComment}
                        handleViewResume={this.handleViewResume}
                        handleReloadData={this.handleSearchTermSubmit}
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    {this.props.userRole != "Dataentry Operator" ? (
                      <Grid item md={2}>
                        <Button
                          className={"viewresbtn"}
                          disabled={this.state.groupMailMembers.length == 0}
                          onClick={this.handleGroupMail}
                        >
                          Group mail
                        </Button>
                      </Grid>
                    ) : null}
                    <Grid item md={10}>
                      <Button
                        className={"viewresbtn"}
                        onClick={this.toggleSaveSearch}
                      >
                        Saved searches
                      </Button>
                    </Grid>
                  </Grid>
                </Container>
              </Grid>
            </Grid>
            {this.state.openAddResume ? (
              <AddResume
                openAddResume={this.state.openAddResume}
                closeAddResume={this.toggleAddResume}
                filterConfig={this.state.filterConfig}
                filterLoading={this.state.filterLoading}
                refreshResume={this.handleSearchTermSubmit}
                resumeData={this.state.resumeData}
                editId={this.state.editId}
              />
            ) : null}
            {this.state.openViewResume ? (
              <ViewResume
                searchTerm={this.state.searchTerm}
                searchArray={searchArray}
                editResume={this.editResume}
                userRole={this.props.userRole}
                handleViewResume={this.handleViewResume}
                openViewResume={this.state.openViewResume}
                closeAddResume={this.toggleAddResume}
                closeViewResume={this.closeViewResume}
                resume={this.state.resumeData}
              />
            ) : null}
            {this.state.openGroupEmail ? (
              <GroupEmail
                resumes={this.state.resumes}
                groupMailMembers={this.state.groupMailMembers}
                closeModal={this.handleGroupMail}
                openGroupEmail={this.state.openGroupEmail}
              />
            ) : null}

            {this.state.openCommentDialog ? (
              <Commentdialog
                resumeData={this.state.resumeData}
                handleToggleComment={this.handleToggleComment}
                empId={this.state.openCommentDialog}
              />
            ) : null}
            {this.state.openSaveSearch ? (
              <SaveSearch
                filterOptions={this.state.filterOptions}
                searchTerm={this.state.searchTerm}
                openSaveSearch={this.state.openSaveSearch}
                applyFilter={this.applyFilter}
                closeSearch={this.toggleSaveSearch}
                appliedSearch={this.state.appliedSearch}
              />
            ) : null}
          </Box>
        </Box>
      </ErrorBoundary>
    );
  }
}
