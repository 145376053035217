import React, { useState, useEffect, useRef } from "react";
import dayjs from "dayjs";

import isoWeek from 'dayjs/plugin/isoWeek'; 
import { Dialog, DialogContent, DialogTitle, Grid } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import FolderIcon from "@mui/icons-material/Folder";
import AddIcon from "@mui/icons-material/Add";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import "./userTrip.css";
import AddTrip from "./addTrip";
import UserTripList from "./UserTripList";
import CloseIcon from "@mui/icons-material/Close";
import GridViewIcon from "@mui/icons-material/GridView";
import ListIcon from "@mui/icons-material/List";
import axios from "axios";
import { _BASE_URL, _TRIP_PATH } from "../../Config/Constants";
import { type } from "@testing-library/user-event/dist/type";
import AddProject from "../Project/addProject";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ViewResumeSummary from "../Dashboard/Dialogs/ViewResumeSummary";
import CircularProgress from '@mui/material/CircularProgress';
dayjs.extend(isoWeek);
const UserTrip = ({
  userTrips,
  closeDialog,
  singleTripSelected,
  handleEdit,
  handleDelete,
  getTripList,
  triptypeList,
  addTrip,
  currentYear,
  memberId,
  formActions,
  userList
}) => {
  const [open, setOpen] = useState(false);
  const [year, setYear] = useState(currentYear);
  const [user, setUser] = useState({ supervisorName: "", id: "" });
  const [showForm, setShowForm] = useState(false);
  const [viewType, setViewType] = useState(1);
  const [formAction, setFormAction] = useState("add");
  const [singleTrip, setSingleTrip] = useState({});
  const [uploadLoading, setUploadLoading] = useState(false);
  const [singleProject,setSingleProject]=useState({});
  const [resumeData,setResumeData]=useState({});
  const [filelist,setFilelist]=useState({});
  const [showFile,setShowFile]=useState(false);
  const inputFile = useRef(null);
  useEffect(()=>{
    if(formActions == "add"){
      setFormAction("add")
}
  },[formActions])
  useEffect(() => {
    if (singleTripSelected){
      setSingleTrip(singleTripSelected);
    } 
  }, [singleTripSelected]);
  useEffect(()=>{
    if(typeof singleTrip.files !="undefined" && singleTrip.files.length>0){
      setFilelist(singleTrip.files);
    }
  },[singleTrip]);
  
  useEffect(() => {
    if (userTrips && userTrips.length > 0) {
      setOpen(true);
      setUser(userTrips[0]);
    } else if (addTrip) {
      setOpen(true);
      setUser({});
      setShowForm(true);
      setFormAction("add");
    } else {
      setOpen(false);
    }
    console.log("userTripsuserTrips", userTrips);
  }, [userTrips, addTrip]);
  // useEffect(() => {
  //   if (addTrip) {
  //     setOpen(true);
  //     setUser({});
  //   } else {
  //     setOpen(false);
  //   }
  //   console.log("userTripsuserTrips", userTrips);
  // }, [addTrip,userTrips]);
 const getWeek=(date)=>{
  const inputDate = dayjs(date);
  return inputDate.isoWeek();
 }
  const getTripColInfo = (cellDate, d) => {
    console.log("cellDatecellDate", cellDate);
    let cls = "";
    const member = userTrips;
    let flgHastrip = false;
    let trips = member.map((mem) => {
      if (dayjs(cellDate).isBetween(mem.fromDate, mem.toDate, "day", "[]")) {
        cls = "";
        if (
        ( Number(mem.onwardJourney)&&
          dayjs(cellDate).isBetween(
            mem.fromDate,
            dayjs(mem.fromDate).add(mem.onwardJourney-1, "day"),
            "day",
            "[]"
          )) ||
          (Number(mem.returnJourney)&&
          dayjs(cellDate).isBetween(
            mem.toDate,
            dayjs(mem.toDate).subtract(mem.returnJourney-1, "day"),
            "day",
            "[]"
          ))
        ) {
          cls = "_opec_cell ";
        }
        return (
          <div
            className={"_trip-days _cl_dt_cell " + cls}
            style={{ backgroundColor: mem.color,cursor:"pointer" }}
            onClick={()=>setSingleTrip(mem)}
          >
            {d}
          </div>
        );
      }
      return null; //<div className="_cl_dt_cell">{d}</div>; //<div className="_no-trip"></div>;
    });
    trips = trips.filter((tr) => tr != null);
    if (trips[0] == null) trips.push(<div className="_cl_dt_cell">{d}</div>);
    console.log("tripstripstrips", trips, cellDate);
    return trips;
  };

  const getCalDates = (month) => {
    const startDate = year + "-" + (month < 9 ? "0" : "") + (month + 1) + "-01";
    const monthYear = year + "-" + (month < 9 ? "0" : "") + (month + 1) + "-";
    let monthStart = dayjs(startDate).format("d");
    monthStart = monthStart == 0 ? 7 : monthStart;
    const noDaysInMonth = dayjs(startDate).daysInMonth();
    let d = 1;
    let weekNo = 6;
    return (
      <table width={"100%"} className={"userTrip-cl"}>
        <thead>
          <tr>
            <th colSpan={7}>
              {dayjs(startDate).format("MMM")}
            </th>
          </tr>
          <tr className="_user_cl_th">
            <th  className={"wk-cal"}>Wk</th>
            <th>
              <div>M</div>
            </th>
            <th>
              <div>T</div>
            </th>
            <th>
              <div>W</div>
            </th>
            <th>
              <div>T</div>
            </th>
            <th>
              <div>F</div>
            </th>
            <th>
              <div>S</div>
            </th>
            <th>
              <div>S</div>
            </th>
          </tr>
        </thead>
        <tbody>
          {Array.from(Array(weekNo).keys()).map((weekIndex) => {
            return (
              <tr>
                <td className={"wk-cal"}>
                  { d <= noDaysInMonth?getWeek(monthYear + "" + (d < 10 ? "0" + d : d)):""}
                </td>
                {Array.from(Array(7).keys()).map((dayIndex) => {
                  return (
                    <td>
                      {(weekIndex == 0 && dayIndex < monthStart - 1) ||
                      d > noDaysInMonth
                        ? ""
                        : getTripColInfo(
                            monthYear + "" + (d < 10 ? "0" + d : d),
                            d++
                          )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };
  const handleResumeUpload = (fileType, e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append(fileType, file, file.name);
    formData.append("docType", fileType);
    formData.append("trip_id", singleTrip.id);
    let _url = _TRIP_PATH + "/uploadDoc";
    setUploadLoading(true);
    axios
      .post(_url, formData)
      .then((response) => {
        const resData =response.data.tripFileList;
        
        setFilelist(resData);
        setUploadLoading(false);
        alert("File Uploaded Successfully")

        //  this.setState({ uploadProgress:'' });
      })
      .catch((err) => {
        setUploadLoading(false);
        alert("error");
        console.log(err);
        //  this.setState({ uploadProgress:'' });
      });
  };
  const viewProjectHandle=(projId)=>{
    console.log("projId",projId)
    axios
      .get(_TRIP_PATH + "/getProject/" + projId)
      .then((response) => {
        // console.log("responseresponse", response);
        if (response.data.project) {
          setSingleProject(response.data.project);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const viewProfileHandle=(cl)=>{
    console.log("cl",cl);
    //ViewResumeSummary
    axios.get(_BASE_URL + "/get-resume/" + cl)
    .then((result) => {
      if (result.status == 200) {
        //this.setState({ resumeData: result.data, viewResumeLoading: false });
        setResumeData(result.data);
      }
    })
  }
  const downloadFile=(doc)=>{
    let fileNameWithoutExtension = (doc.title).split('.')[0];
    window.open(_BASE_URL + "/FrondEndController/downloadDoc?path=" + doc.path+"&doc_name="+fileNameWithoutExtension).focus();
    
  }
  const handleDeleteFile=(files)=>{
    if (!window.confirm("Are you sure you want to delete?"))
    return;
    const payload={...files,id_trip:singleTrip.id}
    let _url = _TRIP_PATH + "/deleteFiles";
    axios
    .post(_url, payload)
    .then((response) => {
      setFilelist(response.data);
      setUploadLoading(false);
      alert("File Deleted Successfully")

      //  this.setState({ uploadProgress:'' });
    })
    .catch((err) => {
      setUploadLoading(false);
      alert("error");
      console.log(err);
      //  this.setState({ uploadProgress:'' });
    });
  }
  const handleDuplicate=()=>{
    setShowForm(true);
    setFormAction("duplicate");
    const tempTrip={...singleTrip}
    setSingleTrip(tempTrip);
    
  }
  return (
    <Dialog
      fullWidth={true}
      maxWidth={false}
      open={open}
      onClose={closeDialog}
      scroll={"body"}
      className={"__dlcl-paper"}
      style={{ background: "#ccc" }}
    >
      <Grid container style={{ backgroundColor: "#ccc" }}>
        <Grid item sm={7}>
          <h2 className="_dialog-title-cl">
            Trip details ({year}) : {user.supervisorName}
          </h2>
        </Grid>
        <Grid item sm={4} style={{ paddingTop: "10px" }}>
          <IconButton onClick={() => setViewType(1)} size={"small"}>
            <GridViewIcon />
          </IconButton>
          <IconButton onClick={() => setViewType(2)} size={"small"}>
            <ListIcon />
          </IconButton>
        </Grid>
        <Grid item sm={1} style={{ textAlign: "right", paddingTop: "10px" }}>
          <IconButton onClick={() => closeDialog()} size={"small"}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item lg={9} sm={6} md={6} className={"_user_cl_container"}>
            {viewType == 1 ? (
              <Grid container spacing={1} className={"cal-con"}>
                {Array.from(Array(12).keys()).map((item) => {
                  return (
                    <Grid item md={6} lg={4}>
                      {" "}
                      {getCalDates(item)}
                    </Grid>
                  );
                })}
              </Grid>
            ) : (
              <Grid container>
                <Grid item sm={12}>
                  <UserTripList
                    userTrips={userTrips}
                    closeDialog={closeDialog}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    getTripList={getTripList}
                    addTrip={addTrip}
                    currentYear={currentYear}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item sm={6} lg={3} md={6}>
            <Grid container>
            {singleProject?.id||resumeData?.id?
            <Grid item sm={7}>
              <IconButton onClick={() =>{setSingleProject({});setResumeData({}); } }>
              <ArrowBackIcon />
            </IconButton></Grid>:<>
              <Grid item sm={7}>
                {!showForm && formAction!='edit' ? (
                  <IconButton
                    onClick={() => {
                      setShowForm(true);
                      setFormAction("add");
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                ) : (
                  <span>
                                    {!showForm && formAction!='edit' && typeof singleTrip.id != "undefined" ?  (
                  <IconButton
                    onClick={() => {
                      setShowForm(false);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                                  ) : null}

                  </span>
                )}
              </Grid>
              <Grid item sm={5}>
             
             
                {!showForm && formAction!='edit' && typeof singleTrip.id != "undefined" ?  (
                  <> 
                   
                  {uploadLoading?
                  <span className="file-loader-cr" style={{display:"inline-block",width:"22px"}}><CircularProgress size="sm"  /></span>
                  :<IconButton onClick={() => setShowFile(!showFile)} disabled={uploadLoading} >
                      <FolderIcon for="fileUpload" />
                    </IconButton>}
                   
                    <IconButton title="Edit">
                      <EditIcon
                        onClick={() => {
                          setShowForm(true);
                          setFormAction("edit");
                        }}
                      />
                    </IconButton>
                    <IconButton title="Delete" onClick={() => handleDelete(singleTrip)}>
                      <DeleteIcon />
                    </IconButton>
                    <IconButton title="Duplicate" onClick={handleDuplicate}>
                      <ContentCopyIcon />
                    </IconButton>
                  </>
                ) : null}
                
              </Grid></>}
            </Grid>
           
            {(!showFile==false )?

<Grid conteiner className="file-cntr">
    <>
  <strong>Files</strong>
  <label className="uploadfile"> Upload File
  <input
          id="fileUpload"
          ref={inputFile}
          className={"hide"}
          type={"file"}
          onChange={(e) => handleResumeUpload("tripFile", e)}
        /></label>
{(filelist && filelist.length)?
  <table className="_tr_fileList">
  {filelist.map((fl)=><tr key={"_file_key"+fl.id}><td key={"_file_key"+fl.id} onClick={()=>downloadFile(fl)} >
    {fl.title}
    </td>
    <td>
    <IconButton size="small" onClick={() => handleDeleteFile(fl)}>
          <DeleteIcon />
        </IconButton>
    </td>
    </tr>)}
  </table>:<p>No file found.</p> }</> 
</Grid>:null}

            {singleProject?.id||resumeData?.id?
            <>
            {singleProject?.id?
            <AddProject
            singleProject={singleProject}
            handleEdit={()=>{}}
            handleDelete={()=>{}}
            getProjectList={()=>{}}
            isView={true}
          />:<ViewResumeSummary
          searchTerm={""}
          searchArray={[]}
            editResume={{}}
            userRole={'admin'}
            handleViewResume={()=>{}}
            openViewResume={()=>{}}
            closeAddResume={()=>{}}
             closeViewResume={()=>{}}
            resume={resumeData}
        />}
          </>
          :
            <AddTrip
              singleTrip={
                singleTrip && singleTrip.supervisorName
                  ? singleTrip
                  : userTrips.length > 0
                  ? userTrips[0]
                  : {}
              }
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              getTripList={getTripList}
              triptypeList={triptypeList}
              showForm={showForm}
              formAction={formAction}
              supervisor={userTrips && userTrips.length > 0 ? userTrips[0] : {}}
              handleFormShow={() => setShowForm(false)}
              viewProject={viewProjectHandle}
              viewProfile={viewProfileHandle}
              userList={userList}
            />}
            
          </Grid>
          
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default UserTrip;
