import React,{useState,useEffect} from "react";
import Popover from "@mui/material/Popover";
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";
import { IconButton } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { _BASE_URL, _TRIP_PATH } from "../../Config/Constants";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import axios from "axios";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
const initialFilterList={
  "project":[],
  "client":[],
  "triptype":[],
  "pm":[],
  "pma":[]
};
const TripFilter = ({applyFilter,triptypeList,userList}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);  
  const [projectList, setProjectList] = React.useState([]);  
  const [clientList, setClientList] = React.useState([]);
  const [trList,setTrList]=React.useState([triptypeList]);
    const [filterOptions,setFilterOptions]=React.useState(initialFilterList);
    const [selectAllCheck,setSelectAllCheck]=React.useState({
      "project":false,
      "client":false,
      "triptype":false,
      "pm":false,
      "pma":false
    });
    const [expanded,setExpanded] = React.useState("");
  useEffect(()=>{
    getProjectList();
    getClientList();
  },[])
  useEffect(()=>{
    if(triptypeList){
      const ttList=triptypeList.sort((a,b)=>{
        return a.type.localeCompare(b.type);
      })
      setTrList(ttList)
    }
    
  },[triptypeList])
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const getProjectList = () => {
    axios
      .get(_TRIP_PATH + "/getProject")
      .then((response) => {
        const prj=response.data.projectList.sort((a,b)=>{
          return a.projectName.localeCompare(b.projectName);
        })
        setProjectList(prj);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getClientList = () => {
    axios
      .get(_TRIP_PATH + "/getClient")
      .then((response) => {
        const prj=response.data.clientList.sort((a,b)=>{
          return a.client.localeCompare(b.client);
        })
        setClientList(prj);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleFilter = (value, type) => () => {
    let filter = { ...filterOptions };
    console.log("filterOptionsfilterOptions",filterOptions,type)
    if (type === 'project') {
      if (filter.project.indexOf(value) === -1)
        filter.project.push(value);
      else{
        filter.project.splice(filter.project.indexOf(value), 1);
        setSelectAllCheck({...selectAllCheck,project:false});
      }
    }
    if (type === 'client') {
      if (filter.client.indexOf(value) === -1)
        filter.client.push(value);
      else{
        filter.client.splice(filter.client.indexOf(value), 1);
        setSelectAllCheck({...selectAllCheck,client:false});
      }
    }
    if (type === 'triptype') {
      if (filter.triptype.indexOf(value) === -1)
        filter.triptype.push(value);
      else{
        filter.triptype.splice(filter.triptype.indexOf(value), 1);
        setSelectAllCheck({...selectAllCheck,triptype:false});
      }
    }
     if (type === 'pm') {
      if (filter.pm.indexOf(value) === -1)
        filter.pm.push(value);
      else{
        filter.pm.splice(filter.pm.indexOf(value), 1);
        setSelectAllCheck({...selectAllCheck,pm:false});
      }
    }
    if (type === 'pma') {
      if (filter.pma.indexOf(value) === -1)
        filter.pma.push(value);
      else{
        filter.pma.splice(filter.pma.indexOf(value), 1);
        setSelectAllCheck({...selectAllCheck,pma:false});
      }
    }
    
    setFilterOptions(filter);
    applyFilter(filter);
}
const handleSelectAll=(type)=>{
  let temp={...selectAllCheck};
  temp[type]=!selectAllCheck[type]
  setSelectAllCheck(temp);
  let filter = { ...initialFilterList };
  if (type === 'project') {
    if(temp[type])
     filter.project=projectList.map((item)=>item.id)
    else
     filter.project=[];
  }
  if (type === 'client') {
    if(temp[type])
    filter.client=clientList.map((item)=>item.id)
    else
    filter.client=[]
  }
  if (type === 'triptype') {
    if(temp[type])
    filter.triptype=triptypeList.map((item)=>item.id)
    else
    filter.triptype=[];
  }
  if (type === 'pm') {
    if(temp[type])
    filter.pm=userList.map((item)=>item.id)
    else
      filter.pm=[];
  }
  if (type === 'pma') {
    if(temp[type])
    filter.pma=userList.map((item)=>item.id)
    else
      filter.pma=[];
  }
  
  setFilterOptions(filter);
  applyFilter(filter);
  }

// const fetchPmList = (e, item) => {f
//   console.log(e.target.value);
//   axios
//     .get(_BASE_URL + "/UserController/getUsers?search=" + e.target.value)
//     .then((response) => {
//       let cl = response.data.users.map((data) => ({
//         value: parseInt(data.id),
//         label: data.firstname + " " + data.lastname,
//       }));
//       if (item == "pm") setPmList(cl);
//       else setPmList([{value:-1,label:"None"},...cl]);
//     })
//     .catch((err) => {
//       console.log(err);
//     });
// };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
        <div className="_t_filter_box" aria-describedby={id}
                variant="contained"
                onClick={handleClick}>
          

          <IconButton >
                <SearchTwoToneIcon />
                </IconButton>
                <span >
            {filterOptions.client.length==0&&filterOptions.project.length==0?"No Filter added":
            (filterOptions.client.length+filterOptions.project.length)+" Filter added"}
            </span>
            {/* {filterOptions.client.map((item)=>clientList.find(cl=>cl.id==item).client )}
            {filterOptions.project.map((item)=>projectList.find(cl=>cl.id==item).projectName)} */}
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div style={{ maxWidth: "200px" }}>
          <Accordion expanded={expanded === 'project'} onChange={handleChange('project')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header" className="headfil"
            >
              <Typography class="filhead">Projects</Typography>
            </AccordionSummary>
            <AccordionDetails  class="uldev">

             <List >
             <ListItem
                disablePadding
              >
                <ListItemButton role={undefined} onClick={()=>handleSelectAll( 'project')} dense>
                  <ListItemIcon  class="listitem">
                    <Checkbox className={"accche"}
                      edge="start"
                      checked={selectAllCheck.project}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': 'tripTypeChecked' }}
                    />
                  </ListItemIcon>
                  <ListItemText id={'tripTypeChecked'} primary={"Select All"} className={"acccolor"} />
                </ListItemButton>
              </ListItem>
            {projectList && projectList.map((item) => (
              <ListItem  
                key={item.id}
                disablePadding
              >
                <ListItemButton role={undefined} onClick={handleFilter(item.id, 'project')}  dense>
                  <ListItemIcon class="listitem" >
                    <Checkbox className={"accche"}
                      edge="start"
                      checked={filterOptions.project.indexOf(item.id) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': item.id }}
                    />
                  </ListItemIcon>
                  <ListItemText id={item.id} primary={item.projectName} className={"acccolor"} />
                </ListItemButton>
              </ListItem>
            ))}

          </List>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'client'} onChange={handleChange('client')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography class="filhead">Clients</Typography>
            </AccordionSummary>
            <AccordionDetails  class="uldev">
            <ListItem
                disablePadding
              >
                <ListItemButton role={undefined} onClick={()=>handleSelectAll( 'client')} dense>
                  <ListItemIcon  class="listitem">
                    <Checkbox className={"accche"}
                      edge="start"
                      checked={selectAllCheck.client}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': 'tripTypeChecked' }}
                    />
                  </ListItemIcon>
                  <ListItemText id={'tripTypeChecked'} primary={"Select All"} className={"acccolor"} />
                </ListItemButton>
              </ListItem>
            {clientList && clientList.map((item) => (
              <ListItem
                key={item.id}
                disablePadding
              >
                <ListItemButton role={undefined} onClick={handleFilter(item.id, 'client')} dense>
                  <ListItemIcon  class="listitem">
                    <Checkbox className={"accche"}
                      edge="start"
                      checked={filterOptions.client.indexOf(item.id) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': item.id }}
                    />
                  </ListItemIcon>
                  <ListItemText id={item.id} primary={item.client} className={"acccolor"} />
                </ListItemButton>
              </ListItem>
            ))}
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'triptype'} onChange={handleChange('triptype')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography class="filhead">Trip Type</Typography>
            </AccordionSummary>
            <AccordionDetails  class="uldev">
            <ListItem
                disablePadding
              >
                <ListItemButton role={undefined} onClick={()=>handleSelectAll( 'triptype')} dense>
                  <ListItemIcon  class="listitem">
                    <Checkbox className={"accche"}
                      edge="start"
                      checked={selectAllCheck.triptype}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': 'tripTypeChecked' }}
                    />
                  </ListItemIcon>
                  <ListItemText id={'tripTypeChecked'} primary={"Select All"} className={"acccolor"} />
                </ListItemButton>
              </ListItem>
            
            {trList && trList.map((item) => (
              <ListItem
                key={item.id}
                disablePadding
              >
                <ListItemButton role={undefined} onClick={handleFilter(item.id, 'triptype')} dense>
                  <ListItemIcon  class="listitem">
                    <Checkbox className={"accche"}
                      edge="start"
                      checked={filterOptions.triptype.indexOf(item.id) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': item.id }}
                    />
                  </ListItemIcon>
                  <ListItemText id={item.id} primary={item.type} className={"acccolor"} />
                </ListItemButton>
              </ListItem>
            ))}
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'pm'} onChange={handleChange('pm')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography class="filhead">PM</Typography>
            </AccordionSummary>
            <AccordionDetails  class="uldev">
            <ListItem
                disablePadding
              >
                <ListItemButton role={undefined} onClick={()=>handleSelectAll( 'pm')} dense>
                  <ListItemIcon  class="listitem">
                    <Checkbox className={"accche"}
                      edge="start"
                      checked={selectAllCheck.pm}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': 'tripTypeChecked' }}
                    />
                  </ListItemIcon>
                  <ListItemText id={'tripTypeChecked'} primary={"Select All"} className={"acccolor"} />
                </ListItemButton>
              </ListItem>
            {userList && userList.map((item) => (
              <ListItem
                key={item.id}
                disablePadding
              >
                <ListItemButton role={undefined} onClick={handleFilter(item.id, 'pm')} dense>
                  <ListItemIcon  class="listitem">
                    <Checkbox className={"accche"}
                      edge="start"
                      checked={filterOptions.pm.indexOf(item.id) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': item.id }}
                    />
                  </ListItemIcon>
                  <ListItemText id={item.id} primary={item.label} className={"acccolor"} />
                </ListItemButton>
              </ListItem>
            ))}
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'pma'} onChange={handleChange('pma')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography class="filhead">PC</Typography>
            </AccordionSummary>
            <AccordionDetails  class="uldev">
            <ListItem
                disablePadding
              >
                <ListItemButton role={undefined} onClick={()=>handleSelectAll( 'pma')} dense>
                  <ListItemIcon  class="listitem">
                    <Checkbox className={"accche"}
                      edge="start"
                      checked={selectAllCheck.pma}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': 'tripTypeChecked' }}
                    />
                  </ListItemIcon>
                  <ListItemText id={'tripTypeChecked'} primary={"Select All"} className={"acccolor"} />
                </ListItemButton>
              </ListItem>
            {userList && userList.map((item) => (
              <ListItem
                key={item.id}
                disablePadding
              >
                <ListItemButton role={undefined} onClick={handleFilter(item.id, 'pma')} dense>
                  <ListItemIcon  class="listitem">
                    <Checkbox className={"accche"}
                      edge="start"
                      checked={filterOptions.pma.indexOf(item.id) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': item.id }}
                    />
                  </ListItemIcon>
                  <ListItemText id={item.id} primary={item.label} className={"acccolor"} />
                </ListItemButton>
              </ListItem>
            ))}
            </AccordionDetails>
          </Accordion>
        </div>
      </Popover>
    </>
  );
};

export default TripFilter;
